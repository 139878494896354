import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const About = () => {
  const [totalCommits, setTotalCommits] = useState(0);
  const [totalIssues, setTotalIssues] = useState(0);
  const [members, setMembers] = useState({
    liana: { commits: 0, issues: 0 },
    jeffrey: { commits: 0, issues: 0 },
    pranav: { commits: 0, issues: 0 },
    mohammad: { commits: 0, issues: 0 },
    annie: { commits: 0, issues: 0 },
  });

  const gitlabProjectPath = encodeURIComponent('62016072');
  const gitlabToken = 'glpat-CbzSH4FpyupH-YHyeX4x';

  useEffect(() => {
    async function fetchGitlabStats() {
      const headers = {
        Authorization: `Bearer ${gitlabToken}`,
        'Content-Type': 'application/json',
      };

      try {
        // Fetch commits
        const commitResponse = await fetch(
          `https://gitlab.com/api/v4/projects/${gitlabProjectPath}/repository/commits?per_page=100`,
          { headers }
        );
        const commits = await commitResponse.json();
        setTotalCommits(commits.length);

        // Use functional update to modify state based on previous state
        setMembers((prevMembers) => {
          const updatedMembers = { ...prevMembers };
          commits.forEach((commit) => {
            const authorName = commit.author_name.toLowerCase();
            for (const key in updatedMembers) {
              if (authorName.includes(key)) {
                updatedMembers[key].commits += 1;
              }
            }
          });
          return updatedMembers;
        });

        // Fetch issues
        const issueResponse = await fetch(
          `https://gitlab.com/api/v4/projects/${gitlabProjectPath}/issues?per_page=100`,
          { headers }
        );
        const issues = await issueResponse.json();
        setTotalIssues(issues.length);

        // Update issues per assignee
        setMembers((prevMembers) => {
          const updatedMembers = { ...prevMembers };
          issues.forEach((issue) => {
            if (issue.assignee) {
              const assigneeName = issue.assignee.name.toLowerCase();
              for (const key in updatedMembers) {
                if (assigneeName.includes(key)) {
                  updatedMembers[key].issues += 1;
                }
              }
            }
          });
          return updatedMembers;
        });
      } catch (error) {
        console.error('Error fetching data from GitLab:', error);
      }
    }

    fetchGitlabStats();
  }, [gitlabProjectPath, gitlabToken]); // Only run once when component mounts

  return (
    <div className="container mt-5">
      <h1 className="text-center mb-4">About Bright Minds Bold Futures</h1>
      <p className="lead text-center mb-5">
        Our mission is to help individuals with Autism in Texas find supportive and accommodating educational resources and therapy options.
      </p>

      <h2 className="text-center mb-4">Meet the Team</h2>
      <div className="row">
        {['liana', 'jeffrey', 'pranav', 'mohammad', 'annie'].map((memberKey, index) => (
          <div key={index} className="col-md-4 mb-4">
            <div className="card text-center shadow-sm">
              <img
                src={`/member_images/${memberKey}_photo.jpg`}
                alt={memberKey}
                className="card-img-top rounded-circle mx-auto mt-3"
                style={{ width: '150px', height: '150px' }}
              />
              <div className="card-body">
                <h5 className="card-title">{memberKey.charAt(0).toUpperCase() + memberKey.slice(1)}</h5>
                <p className="card-text"><strong>Commits:</strong> {members[memberKey].commits}</p>
                <p className="card-text"><strong>Issues:</strong> {members[memberKey].issues}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <h2 className="text-center mt-5 mb-4">Project Stats</h2>
      <div className="row justify-content-center">
        <div className="col-md-6">
          <ul className="list-group">
            <li className="list-group-item d-flex justify-content-between align-items-center">
              <strong>Total No. of Commits:</strong>
              <span>{totalCommits}</span>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-center">
              <strong>Total No. of Issues:</strong>
              <span>{totalIssues}</span>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-center">
              <strong>Total No. of Unit Tests:</strong>
              <span>X</span>
            </li>
          </ul>
        </div>
      </div>

      <h2 className="text-center mt-5 mb-4">Data Sources</h2>
      <div className="row justify-content-center">
        <div className="col-md-8">
          <ul className="list-group">
            <li className="list-group-item">
              <strong>Scholarship Data (ScholarshipOwl API):</strong> Includes scholarship names, amounts, deadlines, and application links.
            </li>
            <li className="list-group-item">
              <strong>Schools Data (Precisely API):</strong> Provides location, name, and ratings of schools catering to individuals with Autism.
            </li>
            <li className="list-group-item">
              <strong>Therapy & Support Groups (Google Maps API):</strong> Locates therapy centers and support groups based on user-selected locations.
            </li>
          </ul>
        </div>
      </div>

      <h2 className="text-center mt-5 mb-4">Tools Used</h2>
      <div className="row justify-content-center">
        <div className="col-md-8">
          <ul className="list-group">
            <li className="list-group-item">
              <strong>Flask:</strong> Used for backend.
            </li>
            <li className="list-group-item">
              <strong>Bootstrap:</strong> Used for frontend design.
            </li>
            <li className="list-group-item">
              <strong>Postman:</strong> API testing.
            </li>
          </ul>
        </div>
      </div>

      <h2 className="text-center mt-5 mb-4">Project Links</h2>
      <div className="row justify-content-center">
        <div className="col-md-8">
          <ul className="list-group">
            <li className="list-group-item">
              <a href="https://gitlab.com/group-07/BrightMindsBoldFutures" target="_blank" rel="noreferrer">
                GitLab Repository
              </a>
            </li>
            <li className="list-group-item">
              <a href="https://api.postman.com/collections/your-postman-api-id" target="_blank" rel="noreferrer">
                Postman API
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default About;
