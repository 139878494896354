import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import placeholderImage from '../assets/therapy.webp'; // Placeholder for therapies without images
import scholarshipPlaceholder from '../assets/scholarship.jpg'; // Placeholder for scholarships
import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap for styling

const TherapistsInstance = () => {
  const { id } = useParams(); // Get the therapy ID from the route
  const [therapy, setTherapy] = useState(null);
  const [relatedSchools, setRelatedSchools] = useState([]);
  const [relatedScholarships, setRelatedScholarships] = useState([]);

  // Helper function to remove duplicate items by ID
  const removeDuplicatesById = (items) => {
    const uniqueItems = items.filter(
      (item, index, self) => index === self.findIndex((t) => t.id === item.id)
    ); 
    return uniqueItems;
  };

  useEffect(() => {
    // Fetch therapy details by ID
    axios
      .get(`https://backend.brightmindsboldfutures.me/api/therapists/id?id=${id}`)
      .then(response => {
        setTherapy(response.data); // Set the therapy data
      })
      .catch(error => {
        console.error('There was an error fetching the therapy details!', error);
      });

    // Fetch related schools
    axios
      .get(`https://backend.brightmindsboldfutures.me/api/therapies/${id}/schools`)
      .then(response => {
        const uniqueSchools = removeDuplicatesById(response.data); // Filter duplicates by ID
        setRelatedSchools(uniqueSchools); // Set related schools data
      })
      .catch(error => {
        console.error('There was an error fetching the related schools!', error);
      });

    // Fetch related scholarships
    axios
      .get(`https://backend.brightmindsboldfutures.me/api/therapies/${id}/scholarships`)
      .then(response => {
        const uniqueScholarships = removeDuplicatesById(response.data); // Filter duplicates by ID
        setRelatedScholarships(uniqueScholarships); // Set related scholarships data
      })
      .catch(error => {
        console.error('There was an error fetching the related scholarships!', error);
      });
  }, [id]);

  if (!therapy) {
    return <div>Loading...</div>;
  }

  // Helper function to check if the image exists in the public folder
  const imageExists = (url) => {
    const http = new XMLHttpRequest();
    http.open('HEAD', url, false);
    http.send();
    return http.status !== 404;
  };

  // Function to dynamically generate the image URL based on scholarship ID
  const getScholarshipImageUrl = (id) => {
    const extensions = ['png', 'jpeg', 'jpg']; // Possible file extensions
    for (const ext of extensions) {
      const imagePath = `/scholarship_images/pic-${id}.${ext}`;
      if (imageExists(imagePath)) {
        return imagePath;
      }
    }
    return '/scholarship_images/pic-1.png'; // Fallback to placeholder image
  };

  return (
    <div className="container mt-5">
      {/* Therapy Details Section */}
      <div className="therapy-detail">
        
        {/* Therapy Image */}
        <div className="container-fluid p-0">
          <img
            src={therapy.image ? therapy.image : placeholderImage}
            alt={therapy.name}
            className="img-fluid mb-4 w-100"
            style={{ maxHeight: '500px', objectFit: 'cover' }}
          />
        </div>

        {/* Therapy Name */}
        <h1 className="display-4 text-center mb-4">{therapy.name}</h1>

        {/* Therapy Information Section */}
        <div className="row">
          {/* Left column: General Information */}
          <div className="col-md-8">
            {/* Description */}
            <p className="lead">{therapy.description}</p>
          </div>

          {/* Right column: Therapy Details in a Card */}
          <div className="col-md-4">
            <div className="card shadow-sm">
              <div className="card-body">
                <h5 className="card-title">{therapy.name}</h5>
                <p className="card-text">
                  <strong>Therapy Type:</strong> {therapy.type}<br />
                  <strong>Location:</strong> {therapy.location}<br />
                  <strong>Phone Number:</strong> {therapy.phone_number}<br />
                  <strong>Rating:</strong> {therapy.rating === 0.0 ? "No ratings yet" : therapy.rating}
                </p>
                <a href={therapy.website} className="btn btn-primary" target="_blank" rel="noopener noreferrer">
                  Visit Website
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Google Map Section */}
        <div className="map-container mt-5">
          <iframe
            title="Therapy Location"
            src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBqCh4gNBCjis7FbNKjxFRrnurFdtod-MY&q=${encodeURIComponent(therapy.location)}`}
            width="100%"
            height="450"
            allowFullScreen=""
            loading="lazy"
            style={{ border: 0 }}
          ></iframe>
        </div>

        {/* Related Schools and Scholarships Section */}
        <div className="related-section mt-5">
          <h2 className="text-center mb-4">Related Schools and Scholarships</h2>
          <div className="row">
            {/* Related Schools */}
            <div className="col-md-6">
              <h3>Related Schools</h3>
              <div className="row">
                {relatedSchools.slice(0, 2).map((school) => (
                  <div key={school.id} className="col-md-6 mb-4">
                    <div className="card shadow-sm">
                      <img
                        src={school.image ? school.image : placeholderImage}
                        alt={school.name}
                        className="card-img-top"
                        style={{ height: '200px', objectFit: 'cover' }}
                      />
                      <div className="card-body">
                        <h5 className="card-title">{school.name}</h5>
                        <Link to={`/schools/${school.id}`} className="btn btn-primary">View More</Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Related Scholarships */}
            <div className="col-md-6">
              <h3>Related Scholarships</h3>
              <div className="row">
                {relatedScholarships.slice(0, 2).map((scholarship) => (
                  <div key={scholarship.id} className="col-md-6 mb-4">
                    <div className="card shadow-sm">
                      <img
                        src={getScholarshipImageUrl(scholarship.id)}
                        alt={scholarship.name}
                        className="card-img-top"
                        style={{ height: '200px', objectFit: 'cover' }}
                      />
                      <div className="card-body">
                        <h5 className="card-title">{scholarship.name}</h5>
                        <Link to={`/scholarships/${scholarship.id}`} className="btn btn-primary">View More</Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TherapistsInstance;
