import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import default styles for the carousel
import './Home.css'; // Custom styles for the carousel
import { Link } from 'react-router-dom';
import home1 from '../assets/home1.jpg';
import home2 from '../assets/home2.jpg';
import home3 from '../assets/home3.webp';
import aboutUsImage from '../assets/about-us.jpg'; // Replace with your images
import schoolImage from '../assets/school.jpg';
import scholarshipImage from '../assets/scholarship.jpg';
import therapyImage from '../assets/therapy.webp';

const Home = () => {
  return (
    <div>
      {/* Carousel Section */}
      <div className="carousel-container">
        <Carousel
          showArrows={true}
          showStatus={false}
          showIndicators={true}
          infiniteLoop={true}
          autoPlay={true}
          interval={10000}
          stopOnHover={false}
          showThumbs={false}
        >
          <div className="carousel-slide">
            <img src={home1} alt="Slide 1" />
            <div className="carousel-overlay">
              <h2 className="carousel-title">Empowering Through Education</h2>
              <p className="carousel-description">
                We strive to help children with autism access the right education tailored to their unique needs.
              </p>
            </div>
          </div>
          <div className="carousel-slide">
            <img src={home2} alt="Slide 2" />
            <div className="carousel-overlay">
              <h2 className="carousel-title">Therapies for Every Child</h2>
              <p className="carousel-description">
                Connecting families to specialized therapies that make a difference in children's lives.
              </p>
            </div>
          </div>
          <div className="carousel-slide">
            <img src={home3} alt="Slide 3" />
            <div className="carousel-overlay">
              <h2 className="carousel-title">Supportive Community</h2>
              <p className="carousel-description">
                Offering access to scholarships and community support for families.
              </p>
            </div>
          </div>
        </Carousel>
      </div>

      {/* Quote Section */}
      <div className="container quote-section my-5">
        <p className="quote-text">
          "Autism spectrum disorder (ASD) is a neurological and developmental disorder that affects how people interact
          with others, communicate, learn, and behave."
        </p>
        <div className="quote-source">
          –{' '}
          <a
            href="https://www.nimh.nih.gov/health/topics/autism-spectrum-disorders-asd"
            target="_blank"
            rel="noopener noreferrer"
          >
            National Institute of Mental Health
          </a>
        </div>
      </div>

      {/* About Section */}
      <div className="container my-5" id="learn-more">
        <div className="row align-items-center">
          <div className="col-md-6">
            <img src={aboutUsImage} alt="About Us" className="img-fluid rounded" />
          </div>
          <div className="col-md-6">
            <h2 className="section-title">About Bright Minds Bold Futures</h2>
            <p>
              Our mission is to provide resources and support to individuals with Autism across the United States. We
              connect families with the best schools, scholarships, and therapy options.
            </p>
            <Link to="/about" className="btn btn-secondary">
              Read More
            </Link>
          </div>
        </div>
      </div>

      {/* Services Section */}
      <div className="container services-section">
        <div className="card">
          <img src={scholarshipImage} className="card-img-top" alt="Scholarships" />
          <div className="card-body">
            <h5 className="card-title">Scholarships</h5>
            <p className="card-text">Access various scholarships tailored to support individuals with Autism.</p>
            <Link to="/scholarships" className="btn btn-primary">
              Learn More
            </Link>
          </div>
        </div>

        <div className="card">
          <img src={schoolImage} className="card-img-top" alt="Schools" />
          <div className="card-body">
            <h5 className="card-title">Schools</h5>
            <p className="card-text">Find Autism-friendly schools and educational programs across the country.</p>
            <Link to="/schools" className="btn btn-primary">
              Learn More
            </Link>
          </div>
        </div>

        <div className="card">
          <img src={therapyImage} className="card-img-top" alt="Therapy" />
          <div className="card-body">
            <h5 className="card-title">Therapy & Support</h5>
            <p className="card-text">
              Discover therapy options that cater to the unique needs of individuals with Autism.
            </p>
            <Link to="/therapy" className="btn btn-primary">
              Learn More
            </Link>
          </div>
        </div>
      </div>

      {/* Testimonials Section */}
      <section className="testimonial-section">
        <div className="container">
          <h2 className="section-title text-center">What Our Community Says</h2>
          <div className="row">
            <div className="col-md-4">
              <div className="testimonial-card">
                <p>"Bright Minds Bold Futures helped us find a school where my son thrives. The resources are invaluable." – Parent</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="testimonial-card">
                <p>"Thanks to the scholarship database, I was able to secure funding for my studies." – Student</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="testimonial-card">
                <p>"The therapy resources offered us guidance and support when we needed it most." – Family</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Video Section */}
      <div className="container my-5">
        <h2 className="section-title text-center">Watch Our Impact</h2>
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                className="embed-responsive-item"
                src="https://www.youtube.com/embed/qbDqQcV6hds"
                allowFullScreen
                title="Autism Awareness Video"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
