import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import placeholderImage from '../assets/scholarship.jpg'; // Placeholder for scholarships without images
import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap for styling

const ScholarshipsInstance = () => {
  const { id } = useParams(); // Get the scholarship ID from the route
  const [scholarship, setScholarship] = useState(null);
  const [relatedSchools, setRelatedSchools] = useState([]);
  const [relatedTherapies, setRelatedTherapies] = useState([]);

  // Array of YouTube video links
  const youtubeVideos = [
    "https://www.youtube.com/embed/oCfQ0LzxP5M",
    "https://www.youtube.com/embed/-TW-mUU2tRE",
    "https://www.youtube.com/embed/rHTNkBg-X7A",
    "https://www.youtube.com/embed/Dzpb_DVPNUQ",
    "https://www.youtube.com/embed/NvImn5fUEpg"
  ];

  // Function to get a random YouTube video
  const getRandomVideo = () => {
    const randomIndex = Math.floor(Math.random() * youtubeVideos.length);
    return youtubeVideos[randomIndex];
  };

  // Function to fetch related schools and therapies
  useEffect(() => {
    // Fetch scholarship details by ID
    axios
      .get(`https://backend.brightmindsboldfutures.me/api/scholarships/id?id=${id}`)
      .then(response => {
        setScholarship(response.data); // Set the scholarship data
      })
      .catch(error => {
        console.error('There was an error fetching the scholarship details!', error);
      });

    // Fetch related schools
    axios
      .get(`https://backend.brightmindsboldfutures.me/api/scholarships/${id}/schools`)
      .then(response => {
        setRelatedSchools(response.data); // Set related schools data
      })
      .catch(error => {
        console.error('There was an error fetching the related schools!', error);
      });

    // Fetch related therapies
    axios
      .get(`https://backend.brightmindsboldfutures.me/api/scholarships/${id}/therapies`)
      .then(response => {
        setRelatedTherapies(response.data); // Set related therapies data
      })
      .catch(error => {
        console.error('There was an error fetching the related therapies!', error);
      });
  }, [id]);

  if (!scholarship) {
    return <div>Loading...</div>;
  }

  // Image algorithm for schools and therapies (same as before)
  const getImageUrl = (item) => {
    return item.image ? item.image : placeholderImage;
  };

  return (
    <div className="container mt-5">
      {/* Scholarship Details Section */}
      <div className="scholarship-detail">
        
        {/* Scholarship Image */}
        <div className="container-fluid p-0">
          <img
            src={scholarship.image ? scholarship.image : placeholderImage}
            alt={scholarship.name}
            className="img-fluid mb-4 w-100"
            style={{ maxHeight: '500px', objectFit: 'cover' }}
          />
        </div>

        {/* Scholarship Name */}
        <h1 className="display-4 text-center mb-4">{scholarship.name}</h1>

        {/* Scholarship Information Section */}
        <div className="row">
          {/* Left column: General Information */}
          <div className="col-md-8">
            {/* Description */}
            <p className="lead">{scholarship.description}</p>

            {/* Embedded YouTube Video */}
            <div className="embed-responsive embed-responsive-16by9 mt-4">
              <iframe
                className="embed-responsive-item"
                src={getRandomVideo()}
                title="Help in Applying for Scholarships"
                allowFullScreen
              ></iframe>
            </div>
          </div>

          {/* Right column: Scholarship Details in a Card */}
          <div className="col-md-4">
            <div className="card shadow-sm">
              <div className="card-body">
                <h5 className="card-title">{scholarship.name}</h5>
                <p className="card-text">
                  <strong>Eligibility:</strong> {scholarship.eligibility}<br />
                  <strong>Amount:</strong> {scholarship.amount}<br />
                  <strong>Deadline:</strong> {scholarship.deadline}
                </p>
                <a href={scholarship.website} className="btn btn-primary" target="_blank" rel="noopener noreferrer">
                  Visit Website
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Related Schools and Therapies Section */}
        <div className="related-section mt-5">
          <div className="row">
            {/* Related Schools */}
            <div className="col-md-6">
              <h3>Related Schools</h3>
              <div className="row">
                {relatedSchools.slice(0, 2).map((school) => (
                  <div key={school.id} className="col-md-6 mb-4">
                    <div className="card shadow-sm">
                      <img
                        src={getImageUrl(school)}
                        alt={school.name}
                        className="card-img-top"
                        style={{ height: '200px', objectFit: 'cover' }}
                      />
                      <div className="card-body">
                        <h5 className="card-title">{school.name}</h5>
                        <Link to={`/schools/${school.id}`} className="btn btn-primary">View More</Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Related Therapies */}
            <div className="col-md-6">
              <h3>Related Therapies</h3>
              <div className="row">
                {relatedTherapies.slice(0, 2).map((therapy) => (
                  <div key={therapy.id} className="col-md-6 mb-4">
                    <div className="card shadow-sm">
                      <img
                        src={getImageUrl(therapy)}
                        alt={therapy.name}
                        className="card-img-top"
                        style={{ height: '200px', objectFit: 'cover' }}
                      />
                      <div className="card-body">
                        <h5 className="card-title">{therapy.name}</h5>
                        <Link to={`/therapies/${therapy.id}`} className="btn btn-primary">View More</Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScholarshipsInstance;
