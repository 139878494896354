import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './Scholarships.css';
import placeholderScholarship from '../assets/scholarship.jpg';

const getScholarshipImageUrl = (id) => {
  const extensions = ['png', 'jpeg', 'jpg'];
  for (const ext of extensions) {
    const imagePath = `/scholarship_images/pic-${id}.${ext}`;
    if (imageExists(imagePath)) {
      return imagePath;
    }
  }
  return '/scholarship_images/pic-1.png';
};

const imageExists = (url) => {
  const http = new XMLHttpRequest();
  http.open('HEAD', url, false);
  http.send();
  return http.status !== 404;
};

const highlightText = (text, searchTerm) => {
  if (!searchTerm) return text;
  const regex = new RegExp(`(${searchTerm})`, 'gi');
  const parts = text.split(regex);
  return parts.map((part, index) =>
    part.toLowerCase() === searchTerm.toLowerCase() ? (
      <span key={index} style={{ backgroundColor: 'yellow', fontWeight: 'bold' }}>{part}</span>
    ) : (
      part
    )
  );
};

const organizations = [
  // list of predefined organizations
];

const Scholarships = () => {
  const [scholarships, setScholarships] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const scholarshipsPerPage = 9;
  const [searchQuery, setSearchQuery] = useState('');
  const [location, setLocation] = useState('');
  const [amount, setAmount] = useState('');
  const [deadline, setDeadline] = useState('');
  const [organization, setOrganization] = useState('');
  const [hasWebsite, setHasWebsite] = useState(false);
  const [error, setError] = useState(null);
  const [sortBy, setSortBy] = useState('name'); // Default sorting by name
  const [order, setOrder] = useState('asc'); // Default to ascending order

  useEffect(() => {
    const source = axios.CancelToken.source();
    axios
      .get(`https://backend.brightmindsboldfutures.me/api/scholarships`, {
        params: {
          page: currentPage,
          limit: scholarshipsPerPage,
          query: searchQuery,
          location,
          amount,
          deadline,
          organization,
          has_website: hasWebsite,
          sort_by: sortBy,
          order,
        },
        cancelToken: source.token,
      })
      .then((response) => {
        setScholarships(response.data.data);
        setTotalPages(response.data.total_pages);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log('Request canceled:', error.message);
        } else {
          setError('Error fetching data');
        }
      });
    return () => {
      source.cancel();
    };
  }, [currentPage, searchQuery, location, amount, deadline, organization, hasWebsite, sortBy, order]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleFilterChange = (setter) => (e) => {
    setter(e.target.value);
    setCurrentPage(1);
  };

  const handleSortChange = (newSortBy) => {
    if (sortBy === newSortBy) {
      setOrder(order === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(newSortBy);
      setOrder('asc');
    }
    setCurrentPage(1);
  };

  const nextPage = () => currentPage < totalPages && setCurrentPage(currentPage + 1);
  const previousPage = () => currentPage > 1 && setCurrentPage(currentPage - 1);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <div className="container-fluid p-0">
        <div className="image-overlay-container">
          <img src={placeholderScholarship} alt="Top Image" className="img-fluid w-100" />
          <div className="image-overlay">
            <h1 className="display-4 text-white">Scholarships Directory</h1>
            <p className="lead text-white">Discover scholarships available for individuals with autism across the United States.</p>
          </div>
        </div>
      </div>

      {/* Search Bar and Filters */}
      <div className="container my-4">
        <input
          type="text"
          className="form-control mb-3"
          placeholder="Search for scholarships by name or organization"
          value={searchQuery}
          onChange={handleSearchChange}
        />

        {/* Sorting Buttons */}
        <div className="d-flex justify-content-end mb-3">
          <button onClick={() => handleSortChange('name')} className="btn btn-outline-primary mx-1">
            Sort by Name {sortBy === 'name' ? (order === 'asc' ? '↑' : '↓') : ''}
          </button>
          <button onClick={() => handleSortChange('amount')} className="btn btn-outline-primary mx-1">
            Sort by Amount {sortBy === 'amount' ? (order === 'asc' ? '↑' : '↓') : ''}
          </button>
          <button onClick={() => handleSortChange('deadline')} className="btn btn-outline-primary mx-1">
            Sort by Deadline {sortBy === 'deadline' ? (order === 'asc' ? '↑' : '↓') : ''}
          </button>
          <button onClick={() => handleSortChange('website')} className="btn btn-outline-primary mx-1">
            Sort by Website {sortBy === 'website' ? (order === 'asc' ? '↑' : '↓') : ''}
          </button>
        </div>

        <div className="row">
          <div className="col-md-4">
            <select
              className="form-select"
              value={location}
              onChange={handleFilterChange(setLocation)}
            >
              <option value="">Select Location</option>
              <option value="Austin">Austin</option>
              <option value="Dallas">Dallas</option>
              <option value="San Antonio">San Antonio</option>
              <option value="Houston">Houston</option>
              {/* Add more locations as needed */}
            </select>
          </div>
          
          <div className="col-md-4">
            <select
              className="form-select"
              value={amount}
              onChange={handleFilterChange(setAmount)}
            >
              <option value="">Select Amount</option>
              <option value="1000">Up to $1,000</option>
              <option value="5000">Up to $5,000</option>
              <option value="10000">Up to $10,000</option>
              {/* Add more amounts as needed */}
            </select>
          </div>
          
          <div className="col-md-4">
            <select
              className="form-select"
              value={deadline}
              onChange={handleFilterChange(setDeadline)}
            >
              <option value="">Select Deadline</option>
              <option value="12/31/2024">Before 12/31/2024</option>
              <option value="06/30/2025">Before 06/30/2025</option>
              <option value="12/31/2025">Before 12/31/2025</option>
              {/* Add more deadlines as needed */}
            </select>
          </div>

          <div className="col-md-4 mt-3">
            <select
              className="form-select"
              value={organization}
              onChange={handleFilterChange(setOrganization)}
            >
              <option value="">Select Organization</option>
              {organizations.map((org, index) => (
                <option key={index} value={org}>{org}</option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="container mt-5 pb-5">
        <p className="text-center">
          Below is a list of scholarships that are available to students with autism. The information includes the scholarship's name, organization, amount, location, and application deadline.
        </p>

        <div className="row">
          {scholarships.map((scholarship, index) => (
            <div key={index} className="col-md-4 d-flex align-items-stretch">
              <div className="card mb-4 shadow-sm">
                <img src={getScholarshipImageUrl(scholarship.id)} alt={scholarship.name} className="card-img-top" />
                <div className="card-body">
                  <h5 className="card-title text-primary">{highlightText(scholarship.name, searchQuery)}</h5>
                  <p className="card-text"><strong>Organization:</strong> {highlightText(scholarship.organization, searchQuery)}</p>
                  <p className="card-text"><strong>Location:</strong> {highlightText(scholarship.location, searchQuery)}</p>
                  <p className="card-text"><strong>Amount:</strong> ${highlightText(scholarship.amount.toString(), searchQuery)}</p>
                  <p className="card-text"><strong>Deadline:</strong> {highlightText(scholarship.deadline, searchQuery)}</p>
                  <p className="card-text"><strong>Website:</strong> <a href={scholarship.website} target="_blank" rel="noopener noreferrer">{highlightText(scholarship.website, searchQuery)}</a></p>
                  <Link to={`/scholarships/${scholarship.id}`} className="btn btn-outline-primary">View More</Link>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="pagination-controls d-flex justify-content-center align-items-center mt-4">
          <button className="btn btn-outline-secondary mx-2" onClick={previousPage} disabled={currentPage === 1}>
            Previous
          </button>
          <span className="mx-2">Page {currentPage} of {totalPages}</span>
          <button className="btn btn-outline-secondary mx-2" onClick={nextPage} disabled={currentPage >= totalPages}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
}; 

export default Scholarships;
