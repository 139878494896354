import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import placeholderSchool from '../assets/school.jpg';
import './Schools.css';

const highlightText = (text, searchTerm) => {
  if (!searchTerm) return text;
  const regex = new RegExp(`(${searchTerm})`, 'gi');
  const parts = text.split(regex);
  return parts.map((part, index) =>
    part.toLowerCase() === searchTerm.toLowerCase() ? (
      <span key={index} style={{ backgroundColor: 'yellow', fontWeight: 'bold' }}>{part}</span>
    ) : (
      part
    )
  );
};

const Schools = () => {
  const [schools, setSchools] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const schoolsPerPage = 9;
  const [searchQuery, setSearchQuery] = useState('');
  const [location, setLocation] = useState('');
  const [rating, setRating] = useState('');
  const [educationLevel, setEducationLevel] = useState('');
  const [areaCode, setAreaCode] = useState('');
  const [hasWebsite, setHasWebsite] = useState('with');
  const [error, setError] = useState(null);
  const [sortBy, setSortBy] = useState('name'); // Default sorting by name
  const [order, setOrder] = useState('asc'); // Default to ascending order

  useEffect(() => {
    const source = axios.CancelToken.source();
    axios
      .get(`https://backend.brightmindsboldfutures.me/api/schools`, {
        params: {
          page: currentPage,
          limit: schoolsPerPage,
          query: searchQuery,
          location,
          rating,
          education_level: educationLevel,
          area_code: areaCode,
          has_website: hasWebsite === 'with',
          sort_by: sortBy,
          order,
        },
        cancelToken: source.token,
      })
      .then((response) => {
        setSchools(response.data.data);
        setTotalPages(response.data.total_pages);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log('Request canceled:', error.message);
        } else {
          setError('Error fetching data');
        }
      });
    return () => {
      source.cancel();
    };
  }, [currentPage, searchQuery, location, rating, educationLevel, areaCode, hasWebsite, sortBy, order]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleFilterChange = (setter) => (e) => {
    setter(e.target.value);
    setCurrentPage(1);
  };

  const handleSortChange = (newSortBy) => {
    if (sortBy === newSortBy) {
      setOrder(order === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(newSortBy);
      setOrder('asc');
    }
    setCurrentPage(1);
  };

  const nextPage = () => currentPage < totalPages && setCurrentPage(currentPage + 1);
  const previousPage = () => currentPage > 1 && setCurrentPage(currentPage - 1);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <div className="container-fluid p-0">
        <div className="image-overlay-container">
          <img src={placeholderSchool} alt="Top Image" className="img-fluid w-100" />
          <div className="image-overlay">
            <h1 className="display-4 text-white">Schools Directory</h1>
            <p className="lead text-white">Discover the best schools for children with autism across the United States.</p>
          </div>
        </div>
      </div>

      {/* Search Bar and Filters */}
      <div className="container my-4">
        <input
          type="text"
          className="form-control mb-3"
          placeholder="Search for schools by name, location, or education level"
          value={searchQuery}
          onChange={handleSearchChange}
        />

        {/* Sorting Buttons */}
        <div className="d-flex justify-content-end mb-3">
          <button onClick={() => handleSortChange('name')} className="btn btn-outline-primary mx-1">
            Sort by Name {sortBy === 'name' ? (order === 'asc' ? '↑' : '↓') : ''}
          </button>
          <button onClick={() => handleSortChange('rating')} className="btn btn-outline-primary mx-1">
            Sort by Rating {sortBy === 'rating' ? (order === 'asc' ? '↑' : '↓') : ''}
          </button>
          <button onClick={() => handleSortChange('education_level')} className="btn btn-outline-primary mx-1">
            Sort by Education Level {sortBy === 'education_level' ? (order === 'asc' ? '↑' : '↓') : ''}
          </button>
          <button onClick={() => handleSortChange('website')} className="btn btn-outline-primary mx-1">
            Sort by Website {sortBy === 'website' ? (order === 'asc' ? '↑' : '↓') : ''}
          </button>
        </div>
        
        <div className="row">
          <div className="col-md-3">
            <select
              className="form-select"
              value={location}
              onChange={handleFilterChange(setLocation)}
            >
              <option value="">Select Location</option>
              <option value="Austin">Austin</option>
              <option value="Dallas">Dallas</option>
              <option value="San Antonio">San Antonio</option>
              <option value="Houston">Houston</option>
              <option value="Abiline">Abiline</option>
              {/* Add more locations as needed */}
            </select>
          </div>
          
          <div className="col-md-3">
            <select
              className="form-select"
              value={rating}
              onChange={handleFilterChange(setRating)}
            >
              <option value="">Select Rating</option>
              <option value="5">5 Stars</option>
              <option value="4">4 Stars</option>
              <option value="3">3 Stars</option>
              <option value="2">2 Stars</option>
              <option value="1">1 Star</option>
            </select>
          </div>
          
          <div className="col-md-3">
            <select
              className="form-select"
              value={educationLevel}
              onChange={handleFilterChange(setEducationLevel)}
            >
              <option value="">Select Education Level</option>
              <option value="Preschool">Preschool</option>
              <option value="Elementary">Elementary</option>
              <option value="Middle">Middle</option>
              <option value="High School">High School</option>
              <option value="College">College</option>
            </select>
          </div>

          <div className="col-md-3">
            <select
              className="form-select"
              value={areaCode}
              onChange={handleFilterChange(setAreaCode)}
            >
              <option value="">Select Area Code</option>
              <option value="(325)">(325)</option>
              <option value="(512)">(512)</option>
              <option value="(713)">(713)</option>
              <option value="(817)">(817)</option>
              <option value="(915)">(915)</option>
              {/* Add more area codes as needed */}
            </select>
          </div>
        </div>
      </div>

      {/* Page Content */}
      <div className="container mt-5 pb-5">
        <p className="text-center">
          Below is a list of schools that cater to the needs of children with autism. The information includes the school's name, location, contact number, education level, and a description.
        </p>

        {/* Schools Section */}
        <div className="row">
          {schools.map((school, index) => (
            <div key={index} className="col-md-4 d-flex align-items-stretch">
              <div className="card mb-4 shadow-sm">
                <img src={school.image == null ? placeholderSchool : school.image} alt={school.name} className="card-img-top" />
                <div className="card-body">
                  <h5 className="card-title text-primary">{highlightText(school.name, searchQuery)}</h5>
                  <p className="card-text"><strong>Location:</strong> {highlightText(school.location, searchQuery)}</p>
                  <p className="card-text"><strong>Phone:</strong> {highlightText(school.phone_number, searchQuery)}</p>
                  <p className="card-text"><strong>Level:</strong> {highlightText(school.education_level, searchQuery)}</p>
                  <p className="card-text"><strong>Rating:</strong> {highlightText(school.rating.toString(), searchQuery)}</p>
                  <p className="card-text"><strong>Website:</strong> 
                    {school.website ? (
                      <a href={school.website} target="_blank" rel="noopener noreferrer">{highlightText(school.website, searchQuery)}</a>
                    ) : (
                      'No Website Available'
                    )}
                  </p>
                  <Link to={`/schools/${school.id}`} className="btn btn-outline-primary">View More</Link>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Pagination Controls */}
        <div className="pagination-controls d-flex justify-content-center align-items-center mt-4">
          <button className="btn btn-outline-secondary mx-2" onClick={previousPage} disabled={currentPage === 1}>
            Previous
          </button>
          <span className="mx-2">Page {currentPage} of {totalPages}</span>
          <button className="btn btn-outline-secondary mx-2" onClick={nextPage} disabled={currentPage >= totalPages}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Schools;
