import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Navbar/Navbar';
import Home from './Home/Home';
import Schools from './Schools/Schools';
import Therapists from './Therapists/Therapists';
import Scholarships from './Scholarship/Scholarships';
import About from './About/About';
import SchoolsInstance from './SchoolsInstance/SchoolsInstance';
import TherapistsInstance from './TherapistsInstance/TherapistsInstance';
import ScholarshipsInstance from './ScholarshipsInstance/ScholarshipsInstance';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
    return (
        <Router>
            <Navbar />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/schools/:id" element={<SchoolsInstance />} />
                <Route path="/schools" element={<Schools />} />
                <Route path="/scholarships/:id" element={<ScholarshipsInstance />} />
                <Route path="/scholarships" element={<Scholarships />} />
                <Route path="/therapies/:id" element={<TherapistsInstance />} />
                <Route path="/therapies" element={<Therapists />} />
                <Route path="/about" element={<About />} />
            </Routes>
        </Router>
    );
}

export default App;
