import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import placeholderImage from '../assets/school.jpg'; // Placeholder for schools without images
import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap for styling

const SchoolsInstance = () => {
  const { id } = useParams(); // Get the school ID from the route
  const [school, setSchool] = useState(null);
  const [relatedScholarships, setRelatedScholarships] = useState([]);
  const [relatedTherapies, setRelatedTherapies] = useState([]);

  useEffect(() => {
    // Fetch school details by ID
    axios
      .get(`https://backend.brightmindsboldfutures.me/api/schools/id?id=${id}`)
      .then(response => {
        setSchool(response.data); // Set the school data
      })
      .catch(error => {
        console.error('There was an error fetching the school details!', error);
      });

    // Fetch related scholarships
    axios
      .get(`https://backend.brightmindsboldfutures.me/api/schools/${id}/scholarships`)
      .then(response => {
        setRelatedScholarships(response.data); // Set related scholarships data
      })
      .catch(error => {
        console.error('There was an error fetching the related scholarships!', error);
      });

    // Fetch related therapies
    axios
      .get(`https://backend.brightmindsboldfutures.me/api/schools/${id}/therapies`)
      .then(response => {
        setRelatedTherapies(response.data); // Set related therapies data
      })
      .catch(error => {
        console.error('There was an error fetching the related therapies!', error);
      });
  }, [id]);

  if (!school) {
    return <div>Loading...</div>;
  }

  // Image algorithm for scholarships and therapies (same as before)
  const getImageUrl = (item) => {
    return item.image ? item.image : placeholderImage;
  };
  // Helper function to check if the image exists in the public folder
    const imageExists = (url) => {
        const http = new XMLHttpRequest();
        http.open('HEAD', url, false);
        http.send();
        return http.status !== 404;
    };

    // Function to dynamically generate the image URL based on scholarship ID
    const getScholarshipImageUrl = (id) => {
        const extensions = ['png', 'jpeg', 'jpg']; // Possible file extensions
        for (const ext of extensions) {
        const imagePath = `/scholarship_images/pic-${id}.png`;
            if (imageExists(imagePath)) {
                return imagePath;
            }
        }
        return '/scholarship_images/pic-1.png'; // Fallback to placeholder image
    };
  
  return (
    <div className="container mt-5">
      {/* School Details Section */}
      <div className="school-detail">
        
        {/* School Image */}
        <div className="container-fluid p-0">
          <img
            src={school.image ? school.image : placeholderImage}
            alt={school.name}
            className="img-fluid mb-4 w-100"
            style={{ maxHeight: '500px', objectFit: 'cover' }}
          />
        </div>

        {/* School Name */}
        <h1 className="display-4 text-center mb-4">{school.name}</h1>

        {/* School Information Section */}
        <div className="row">
          {/* Left column: General Information */}
          <div className="col-md-8">
            {/* Description */}
            <p className="lead">{school.description}</p>
          </div>

          {/* Right column: School Details in a Card */}
          <div className="col-md-4">
            <div className="card shadow-sm">
              <div className="card-body">
                <h5 className="card-title">{school.name}</h5>
                <p className="card-text">
                  <strong>Education Level:</strong> {school.education_level}<br />
                  <strong>Location:</strong> {school.location}<br />
                  <strong>Phone Number:</strong> {school.phone_number}<br />
                  <strong>Rating:</strong> {school.rating === 0.0 ? "No ratings yet" : school.rating}
                </p>
                <a href={school.website} className="btn btn-primary" target="_blank" rel="noopener noreferrer">
                  Visit Website
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Google Map Section */}
        <div className="map-container mt-5">
          <iframe
            title="School Location"
            src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBqCh4gNBCjis7FbNKjxFRrnurFdtod-MY&q=${encodeURIComponent(school.location)}`}
            width="100%"
            height="450"
            allowFullScreen=""
            loading="lazy"
            style={{ border: 0 }}
          ></iframe>
        </div>

        {/* Related Scholarships and Therapies Section */}
        <div className="related-section mt-5">
          <h2 className="text-center mb-4">Related Scholarships and Therapies</h2>
          <div className="row">
            {/* Related Scholarships */}
            <div className="col-md-6">
              <h3>Related Scholarships</h3>
              <div className="row">
                {relatedScholarships.slice(0, 2).map((scholarship) => (
                  <div key={scholarship.id} className="col-md-6 mb-4">
                    <div className="card shadow-sm">
                      <img
                        src={getScholarshipImageUrl(scholarship.id)}
                        alt={scholarship.name}
                        className="card-img-top"
                        style={{ height: '200px', objectFit: 'cover' }}
                      />
                      <div className="card-body">
                        <h5 className="card-title">{scholarship.name}</h5>
                        <Link to={`/scholarships/${scholarship.id}`} className="btn btn-primary">View More</Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Related Therapies */}
            <div className="col-md-6">
              <h3>Related Therapies</h3>
              <div className="row">
                {relatedTherapies.slice(0, 2).map((therapy) => (
                  <div key={therapy.id} className="col-md-6 mb-4">
                    <div className="card shadow-sm">
                      <img
                        src={getImageUrl(therapy)}
                        alt={therapy.name}
                        className="card-img-top"
                        style={{ height: '200px', objectFit: 'cover' }}
                      />
                      <div className="card-body">
                        <h5 className="card-title">{therapy.name}</h5>
                        <Link to={`/therapies/${therapy.id}`} className="btn btn-primary">View More</Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SchoolsInstance;
