import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import placeholder from '../assets/placeholder.webp';
import placeholderTherapy from '../assets/therapy.webp';
import './Therapists.css';

const highlightText = (text, searchTerm) => {
  if (!searchTerm) return text;
  const regex = new RegExp(`(${searchTerm})`, 'gi');
  const parts = text.split(regex);
  return parts.map((part, index) =>
    part.toLowerCase() === searchTerm.toLowerCase() ? (
      <span key={index} style={{ backgroundColor: 'yellow', fontWeight: 'bold' }}>{part}</span>
    ) : (
      part
    )
  );
};

const Therapists = () => {
  const [therapists, setTherapists] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const therapistsPerPage = 9;
  const [searchQuery, setSearchQuery] = useState('');
  const [location, setLocation] = useState('');
  const [type, setType] = useState('');
  const [title, setTitle] = useState('');
  const [areaCode, setAreaCode] = useState('');
  const [contactMethod, setContactMethod] = useState('');
  const [error, setError] = useState(null);
  const [sortBy, setSortBy] = useState('name'); // Default sorting by name
  const [order, setOrder] = useState('asc'); // Default to ascending order

  useEffect(() => {
    const source = axios.CancelToken.source();
    axios
      .get(`https://backend.brightmindsboldfutures.me/api/therapists`, {
        params: {
          page: currentPage,
          limit: therapistsPerPage,
          query: searchQuery,
          location,
          type,
          title,
          area_code: areaCode,
          contact_method: contactMethod,
          sort_by: sortBy,
          order,
        },
        cancelToken: source.token,
      })
      .then((response) => {
        setTherapists(response.data.data);
        setTotalPages(response.data.total_pages);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log('Request canceled:', error.message);
        } else {
          setError('Error fetching data');
        }
      });
    return () => {
      source.cancel();
    };
  }, [currentPage, searchQuery, location, type, title, areaCode, contactMethod, sortBy, order]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleFilterChange = (setter) => (e) => {
    setter(e.target.value);
    setCurrentPage(1);
  };

  const handleSortChange = (newSortBy) => {
    if (sortBy === newSortBy) {
      setOrder(order === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(newSortBy);
      setOrder('asc');
    }
    setCurrentPage(1);
  };

  const nextPage = () => currentPage < totalPages && setCurrentPage(currentPage + 1);
  const previousPage = () => currentPage > 1 && setCurrentPage(currentPage - 1);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      {/* Top Image with Overlay Text */}
      <div className="container-fluid p-0">
        <div className="image-overlay-container">
          <img src={placeholderTherapy} alt="Top Image" className="img-fluid w-100" />
          <div className="image-overlay">
            <h1 className="display-4 text-white">Therapies Directory</h1>
            <p className="lead text-white">Discover the best therapists and support for children with autism across the United States.</p>
          </div>
        </div>
      </div>

      {/* Search Bar and Filters */}
      <div className="container my-4">
        <input
          type="text"
          className="form-control mb-3"
          placeholder="Search for therapists by name, organization, or type"
          value={searchQuery}
          onChange={handleSearchChange}
        />

        {/* Sorting Buttons */}
        <div className="d-flex justify-content-end mb-3">
          <button onClick={() => handleSortChange('name')} className="btn btn-outline-primary mx-1">
            Sort by Name {sortBy === 'name' ? (order === 'asc' ? '↑' : '↓') : ''}
          </button>
          <button onClick={() => handleSortChange('type')} className="btn btn-outline-primary mx-1">
            Sort by Type {sortBy === 'type' ? (order === 'asc' ? '↑' : '↓') : ''}
          </button>
          <button onClick={() => handleSortChange('location')} className="btn btn-outline-primary mx-1">
            Sort by Location {sortBy === 'location' ? (order === 'asc' ? '↑' : '↓') : ''}
          </button>
        </div>
        
        <div className="row">
          <div className="col-md-4">
            <select
              className="form-select"
              value={location}
              onChange={handleFilterChange(setLocation)}
            >
              <option value="">Select Location</option>
              <option value="Dallas">Dallas</option>
              <option value="Austin">Austin</option>
              <option value="Houston">Houston</option>
              <option value="San Antonio">San Antonio</option>
              <option value="Plano">Plano</option>
              {/* Add more locations as needed */}
            </select>
          </div>
          
          <div className="col-md-4">
            <select
              className="form-select"
              value={type}
              onChange={handleFilterChange(setType)}
            >
              <option value="">Select Type</option>
              <option value="Psychologist">Psychologist</option>
              <option value="Licensed Professional Counselor">Licensed Professional Counselor</option>
              <option value="Marriage & Family Therapist">Marriage & Family Therapist</option>
              {/* Add more types as needed */}
            </select>
          </div>
          
          <div className="col-md-4">
            <select
              className="form-select"
              value={title}
              onChange={handleFilterChange(setTitle)}
            >
              <option value="">Select Title</option>
              <option value="MS">MS</option>
              <option value="PhD">PhD</option>
              <option value="LPC-S">LPC-S</option>
              <option value="RPT-S">RPT-S</option>
              <option value="CAS">CAS</option>
              <option value="PsyD">PsyD</option>
              {/* Add more titles as needed */}
            </select>
          </div>

          <div className="col-md-4 mt-3">
            <select
              className="form-select"
              value={areaCode}
              onChange={handleFilterChange(setAreaCode)}
            >
              <option value="">Select Area Code</option>
              <option value="(325)">(325)</option>
              <option value="(512)">(512)</option>
              <option value="(713)">(713)</option>
              <option value="(817)">(817)</option>
              <option value="(915)">(915)</option>
              {/* Add more area codes as needed */}
            </select>
          </div>

          <div className="col-md-4 mt-3">
            <select
              className="form-select"
              value={contactMethod}
              onChange={handleFilterChange(setContactMethod)}
            >
              <option value="">Select Contact Method</option>
              <option value="Contact via website">Website</option>
              <option value="other">Other</option>
            </select>
          </div>
        </div>
      </div>

      {/* Page Content */}
      <div className="container mt-5 pb-5">
        <p className="text-center">
          Below is a list of therapists who specialize in helping children with autism. The information includes the therapist's name, organization, location, contact number, type, and description.
        </p>

        {/* Therapists Section */}
        <div className="row">
          {therapists?.map((therapist, index) => (
            <div key={index} className="col-md-4 d-flex align-items-stretch">
              <div className="card mb-4 shadow-sm">
                <img src={therapist.image == null ? placeholder : therapist.image} alt={therapist.name} className="card-img-top" />
                <div className="card-body">
                  <h5 className="card-title text-primary">{highlightText(therapist.name, searchQuery)}</h5>
                  <p className="card-text"><strong>Organization:</strong> {highlightText(therapist.organization, searchQuery)}</p>
                  <p className="card-text"><strong>Location:</strong> {highlightText(therapist.location, searchQuery)}</p>
                  <p className="card-text"><strong>Phone:</strong> {highlightText(therapist.phone_number, searchQuery)}</p>
                  <p className="card-text"><strong>Type:</strong> {highlightText(therapist.type, searchQuery)}</p>
                  <p className="card-text"><strong>Contact:</strong> {highlightText(therapist.email, searchQuery)}</p>
                  <Link to={`/therapies/${therapist.id}`} className="btn btn-outline-primary">View More</Link>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Pagination Controls */}
        <div className="pagination-controls d-flex justify-content-center align-items-center mt-4">
          <button className="btn btn-outline-secondary mx-2" onClick={previousPage} disabled={currentPage === 1}>
            Previous
          </button>
          <span className="mx-2">Page {currentPage} of {totalPages}</span>
          <button className="btn btn-outline-secondary mx-2" onClick={nextPage} disabled={currentPage >= totalPages}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Therapists;
